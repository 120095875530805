import { render, staticRenderFns } from "./OrganismProjectUI.vue?vue&type=template&id=fa0bcd08&"
import script from "./OrganismProjectUI.vue?vue&type=script&lang=js&"
export * from "./OrganismProjectUI.vue?vue&type=script&lang=js&"
import style0 from "./OrganismProjectUI.vue?vue&type=style&index=0&id=fa0bcd08&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculeExtraSidebarComponents: require('/app/components/molecules/project/building/MoleculeExtraSidebarComponents.vue').default,MoleculesProjectBuildingMoleculeExtraSidebarComponentsMobile: require('/app/components/molecules/project/building/MoleculeExtraSidebarComponentsMobile.vue').default,OrganismsProjectBuildingOrganismProjectAvailabilityOptions: require('/app/components/organisms/project/building/OrganismProjectAvailabilityOptions.vue').default,MoleculesCommonMoleculeFiltersPins3D: require('/app/components/molecules/common/MoleculeFiltersPins3D.vue').default})
